/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  // Default accordion
  $('.accordion .accordion__title').click(function (e) {
    $('.accordion .accordion__title').removeClass('js-accordion-open');
    $('.accordion .accordion__content').slideUp();
    if ($(this).next().is(':hidden') == true) {
      $(this).addClass('js-accordion-open');
      $(this).next().slideDown();
    }
    e.preventDefault();
  });

  if ($('.accordion').hasClass('accordion--grad')) {
    $('.accordion--grad .accordion__content').hide();
    $('.accordion--grad .accordion__item:first-child .accordion__content').show();
    $('.accordion--grad .accordion__item:first-child .accordion__title').addClass(
      'js-accordion-open',
    );
  } else {
    $('.accordion .accordion__content').hide();
  }
}(jQuery));
