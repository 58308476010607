/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  Drupal.behaviors.scrollAnimations = {
    attach(context, settings) {
      const $animation_elements = $('.js-section-animation');
      const $window = $(window);

      // For on scroll animations
      function check_if_in_view() {
        const window_height = $window.height();
        const window_top_position = $window.scrollTop();
        const window_bottom_position = window_top_position + window_height;

        $.each($animation_elements, function () {
          const $element = $(this);
          const element_height = $element.outerHeight();
          const element_top_position = $element.offset().top;
          const element_bottom_position = element_top_position + element_height;

          // check to see if this current container is within viewport
          if (
            element_bottom_position >= window_top_position &&
            element_top_position <= window_bottom_position
          ) {
            $element.addClass('in-view');
          } else {
            $element.removeClass('in-view');
          }
        });
      }

      $window.on('scroll resize', check_if_in_view);
      $window.trigger('scroll');
    },
  };
}(jQuery, Drupal));
