/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  if ($('.coe-title__link').length) {
    // Initiate priorityNav
    const coeLabel = document.getElementsByClassName('coe-title__link')[0].innerHTML;

    const nav = priorityNav.init({
      mainNavWrapper: '.coe-menu__nav', // mainnav wrapper selector (must be direct parent from mainNav)
      mainNav: '.coe-menu__items', // mainnav selector. (must be inline-block)
      navDropdownLabel: 'More',
      navDropdownBreakpointLabel: `${coeLabel} Navigation`,
      navDropdownClassName: 'coe-menu__dropdown', // class used for the dropdown.
      navDropdownToggleClassName: 'coe-menu__dropdown-toggle', // class used for the dropdown toggle.
      breakPoint: 900,
      throttleDelay: 0,
    });
  }

  // Trigger priorty nav interactions once js loads
  $(window).on('load', () => {
    // Add / Remove clsses for More link
    $('.coe-menu__dropdown-toggle').hover(
      function () {
        $(this).addClass('js-hover').siblings('.coe-menu__dropdown').addClass('js-hover');
      },
      function () {
        $(this).removeClass('js-hover').siblings('.coe-menu__dropdown').removeClass('js-hover');
      },
    );

    // Ensure More link maintains class when hoving over dropdown
    $('.coe-menu__dropdown').hover(
      function () {
        $(this).siblings('.coe-menu__dropdown-toggle').addClass('js-hover');
      },
      function () {
        $(this).siblings('.coe-menu__dropdown-toggle').removeClass('js-hover');
      },
    );

    // Add class to dropdown on focus
    $('.coe-menu__dropdown a, .coe-menu__dropdown button').focus(function () {
      $(this).parents('.coe-menu__dropdown').addClass('js-focus');
    });

    // Remove class for dropdown when focus out
    $('.coe-menu__dropdown a, .coe-menu__dropdown button').focusout(function () {
      $(this).parents('.coe-menu__dropdown').removeClass('js-focus');
    });

    // Trigger the mobile section nav dropdown
    $('.coe-menu__dropdown .coe-menu__toggle').click(function () {
      if ($(this).hasClass('js-open')) {
        // Close dropdown if click again
        $(this).removeClass('js-open').siblings('.coe-menu__children').hide();
      } else {
        // Open dropdown on click
        $('.coe-menu__dropdown .coe-menu__toggle').removeClass('js-open');
        $('.coe-menu__children').hide();
        $(this).addClass('js-open').siblings('.coe-menu__children').show();
      }
    });
  });

  // Add class to dropdowns on hover
  $('.coe-menu__item--has-dropdown').hover(
    function () {
      if ($(this).parent('.coe-menu__items').length) {
        $(this).addClass('js-hover');
      }
      // Remove hover class on dropdown
    },
    function () {
      if ($(this).parent('.coe-menu__items').length) {
        $(this).removeClass('js-hover');
      }
    },
  );

  // Add focus class to dropdown when dropdown link is focused
  $('.coe-menu__child-link').focus(function () {
    $(this).parents('.coe-menu__children').addClass('js-focus');
  });

  // Remove focus class to dropdown when dropdown link loses focus
  $('.coe-menu__child-link').focusout(function () {
    $(this).parents('.coe-menu__children').removeClass('js-focus');
  });

  // Execute on load and resize
  let resizeId;

  $(window).on('load', dropdownVisibility);
  $(window).on('resize', () => {
    $('.coe-menu__children').removeClass('js-offscreen-left js-offscreen-right');
    $('.coe-menu__item').removeClass('js-offscreen-child');

    clearTimeout(resizeId);
    resizeId = setTimeout(dropdownVisibility, 500);
  });

  function dropdownVisibility() {
    // Loop over each dropdown, and check if it's visible.
    $('.coe-menu__children').each(function () {
      const rect = $(this).clientRect();

      if (rect.left < 0) {
        $(this).addClass('js-offscreen-left');
        $(this).parent('.coe-menu__item').addClass('js-offscreen-child');
      } else if (rect.right > window.innerWidth) {
        $(this).addClass('js-offscreen-right');
        $(this).parent('.coe-menu__item').addClass('js-offscreen-child');
      }
    });
  }
}(jQuery));
