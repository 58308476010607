/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  const $location_services = $('.truncate-list');

  // Find all truncate lists on page load
  $.each($location_services, function () {
      const $this_service = $(this);
      var seeMore = dictionary && dictionary.ShowAll ? dictionary.SeeMore : "See More";
      var seeless = dictionary && dictionary.ShowLess ? dictionary.SeeLess : "See Less";
    // Check that the list has more than 10 items
    if ($this_service.find('ul > li, ol > li').length > 10) {
      // Hide list items after 10
      $this_service.find('ul > li, ol > li').slice(10).hide();
      // Add a button to show all after ul
        $this_service.append(
            `<a class="button--secondary button--small truncate-list__button show" href="#" aria-label="${seeMore}">${seeMore}</a>`,
      );
    }

    $this_service.find('.truncate-list__button').click(function (e) {
      const $this_button = $(this);

      if ($this_button.hasClass('show')) {
          $this_button.removeClass('show').addClass('hide');
          $this_button.html(seeless);
        $this_service.find('ul > li, ol > li').slice(10).show();
      } else {
          $this_button.removeClass('hide').addClass('show');
          $this_button.html(seeMore);
        $this_service.find('ul > li, ol > li').slice(10).hide();
      }
      e.preventDefault();
    });
  });
}(jQuery));
