/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  $('.slider').slick({
    slidesToShow: 1,
    infinite: true,
    adaptiveHeight: false,
  });
}(jQuery));
