/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  // Trigger anchor nav show/hide at mobile
  $('.anchor-aside__trigger').click(function () {
    if ($(this).hasClass('js-is-open')) {
      // Close dropdown if click again
      $(this).removeClass('js-is-open');
      $(this).siblings('.scroll-nav').slideUp();
    } else {
      // Open dropdown on click
      $(this).addClass('js-is-open');
      $(this).siblings('.scroll-nav').slideDown();
    }
  });

  const anchorAside = $('.anchor-aside');

  setTimeout(() => {
    // Trigger the sticky nav on scroll
    if (anchorAside.length) {
      const anchorMainTop = $('.anchor-main').offset().top - 100;
      const anchorMainHeight = anchorMainTop + $('.anchor-main').height();

      $(window).on('scroll', () => {
        if ($(window).scrollTop() > anchorMainTop) {
          anchorAside.addClass('fixed');
          anchorAside.removeClass('bottom');
          const stickablePosition = anchorAside.offset().top + anchorAside.height();

          if (stickablePosition > anchorMainHeight) {
            anchorAside.removeClass('fixed');
            anchorAside.addClass('bottom');
          }
        } else if (anchorMainTop > $(window).scrollTop()) {
          anchorAside.removeClass('fixed');
        }
      });
    }
  }, '200');
}(jQuery));
