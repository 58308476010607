/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  /// /////////////////////////////////////
  // Header/menu interactions
  /// /////////////////////////////////////

  // Trigger menu dropdown when user clicks ENTER on hamburger menu
  $('.menus__hamburger-input').keypress(function (e) {
    if (e.which == 13) {
      // If hamburger menu is checked, remove check
      if ($(this).is(':checked')) {
        $('.menus__hamburger-input').prop('checked', false);
      }
      // If hamburger menu is not checked, add check
      else {
        $(this).prop('checked', true);
      }
    }
  });

  // Show and hide search dropdown
  $('#buttonSearch').click(() => {
    if ($('.menus__search').hasClass('js-search-active')) {
      $('.menus__search').removeClass('js-search-active');
      $('.menus__overlay').removeClass('js-overlay-active');
    } else {
      $('.menus__search').addClass('js-search-active');
      $('.menus__overlay').addClass('js-overlay-active');
      $('.menus__hamburger-input').prop('checked', false);
      $('.desktop-menu-button').removeClass('menu-is-open');
      $('#menus-search-input').focus();
    }
  });

  // Close search dropdown and navigate back to search button
  $('.close-search').click(() => {
    $('.menus__search').removeClass('js-search-active');
    $('.menus__overlay').removeClass('js-overlay-active');
      $('#buttonSearch').focus();
  });

  // Close main menu dropdown and navigate back to hamburger button
  $('.close-main-menu').click(() => {
    $('.menus__hamburger-input').prop('checked', false);
    $('.menus__overlay').removeClass('js-overlay-active');
    $('.desktop-menu-button').removeClass('menu-is-open');
    if ($('.desktop-menu-button').length) {
      $('.desktop-menu-button').focus();
    } else {
      $('.menus__hamburger-input').focus();
    }
  });

  // new menu button in main nav items
  $('.desktop-menu-button').click(function () {
    const checkBoxes = $('.menus__hamburger-input');
    $(this).toggleClass('menu-is-open');
    checkBoxes.prop('checked', !checkBoxes.prop('checked'));
    // close search when clicking on new menu button
    $('.menus__search').removeClass('js-search-active');
    $('.menus__overlay').removeClass('js-overlay-active');
    $('.system-menu__dropdown').focus();
  });

  // Hide search dropdown when clicking on hamburger menu checkbox
  $('.menus__hamburger-input').click(() => {
    $('.menus__search').removeClass('js-search-active');
    $('.menus__overlay').removeClass('js-overlay-active');
  });

  // Hide dropdown when user clicks on overlay
  $('.menus__overlay').click(() => {
    if ($('.menus__search').hasClass('js-search-active')) {
      $('.menus__search').removeClass('js-search-active');
      $('.menus__overlay').removeClass('js-overlay-active');
      $('.button--search').focus();
    } else if ($('.menus__hamburger-input').is(':checked')) {
      $('.menus__hamburger-input').prop('checked', false);
      $('.desktop-menu-button').removeClass('menu-is-open');
      if ($('.desktop-menu-button').length) {
        $('.desktop-menu-button').focus();
      } else {
        $('.menus__hamburger-input').focus();
      }
    }
  });

  /// /////////////////////////////////////
  // Video Modal
  /// /////////////////////////////////////

  $('.js-modal-btn').each(function () {
    $(this).modalVideo({ channel: $(this).data('video-channel') });
  });

  /// /////////////////////////////////////
  // Secondary nav
  /// /////////////////////////////////////

  function sectionNavDisplay() {
    const $window = $(window);
    const $sectionNav = $('.section-nav__dropdown');
    const $bp3 = 900;

    if ($window.width() > $bp3) {
      $sectionNav.show();
    } else {
      $sectionNav.hide();
    }
  }

  // Execute on load and resize
  $(window).on('load', sectionNavDisplay);
  $(window).on('resize', sectionNavDisplay);

  // Trigger the mobile section nav dropdown
  $('.section-nav__trigger').click(function () {
    if ($(this).hasClass('js-is-open')) {
      // Close dropdown if click again
      $(this).removeClass('js-is-open');
      $(this).siblings('.section-nav__dropdown').slideUp();
    } else {
      // Open dropdown on click
      $(this).addClass('js-is-open');
      $(this).siblings('.section-nav__dropdown').slideDown({
        inherit_select_classes: true,
      });
    }
  });

  // Trigger section nav nested children drawer
  $('.section-nav__children-button').click(function () {
    if ($(this).hasClass('js-is-open')) {
      // Close dropdown if click again
      $(this).removeClass('js-is-open');
      $(this).siblings('.section-nav__sublinks').slideUp();
    } else {
      // Open dropdown on click
      $(this).addClass('js-is-open');
      $(this).siblings('.section-nav__sublinks').slideDown();
    }
  });

  /// /////////////////////////////////////
  // Chosen js
  /// /////////////////////////////////////

  // Init chosen js
  $('.js-chosen-select').chosen({
    inherit_select_classes: true,
    width: '100%',
  });

  // Hide insurances results section
  $('.page .insurances-results').hide();

  // Show insurances results on dropdown selection
  $('.js-chosen-insurance-select')
    .chosen({
      inherit_select_classes: true,
      width: '100%',
    })
    .change(() => {
      $('.page .insurances-results').slideDown();
    });

  /// /////////////////////////////////////
  // Rail filter
  /// /////////////////////////////////////

  // Trigger the mobile filter dropdown
  $('.rail-filters__trigger').click(function () {
    if ($(this).hasClass('js-is-open')) {
      // Close dropdown if click again
      $(this).removeClass('js-is-open');
      $(this).siblings('.rail-filters__dropdown').slideUp();
    } else {
      // Open dropdown on click
      $(this).addClass('js-is-open');
      $(this).siblings('.rail-filters__dropdown').slideDown({
        inherit_select_classes: true,
      });
    }
  });

  // conditionally display physician practice types
  $('#facility-type-input').change(function () {
    if ($(this).val() == 'physician-practices') {
      $('#physician-practice-conditional').slideDown();
    } else {
      $('#physician-practice-conditional').slideUp();
    }
  });

  // get the range value and display text label
  $(document).on('input change', '#wait-time-range-input', function () {
    if ($(this).val() == '1') {
      $('.location-range').html('15 Minute Limit');
    } else if ($(this).val() == '2') {
      $('.location-range').html('30 Minute Limit');
    } else if ($(this).val() == '3') {
      $('.location-range').html('1 Hour Limit');
    } else if ($(this).val() == '4') {
      $('.location-range').html('No Wait Time Limit');
    }
  });

  // Trigger the mobile filter dropdown
  $('.rail-filters--location__trigger').click(function () {
    if ($(this).hasClass('js-is-open')) {
      // Close dropdown if click again
      $(this).removeClass('js-is-open');
      $(this).siblings('.rail-filters--location__dropdown').slideUp();
    } else {
      // Open dropdown on click
      $(this).addClass('js-is-open');
      $(this).siblings('.rail-filters--location__dropdown').slideDown({
        inherit_select_classes: true,
      });
    }
  });
}(jQuery));
