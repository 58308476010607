/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  $(document).ready(() => {
    $('.image-gallery').each(function (index, element) {
      $(this).unitegallery({
        lightbox_textpanel_width: 800,
        lightbox_textpanel_enable_description: true,
        lightbox_textpanel_enable_title: false,
        lightbox_textpanel_padding_top: 25,
        lightbox_textpanel_padding_bottom: 20,
        lightbox_textpanel_padding_left: 50,
        lightbox_textpanel_padding_right: 50,
      });
    });
  });
}(jQuery));
