/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  // This code is meant for styleguide presentation purposes ONLY.

  // Hide the select locations section
  $('.insurances-search__select-location').hide();

  // Show the select locations section on radio click
  $('.insurances-search__radio input:radio').click(function () {
    if ($(this).is(':checked')) {
      $('.insurances-search__select-location').slideDown();
    }
  });

  // Show the select locations section when user presses spacebar
  $('.insurances-search__radio input:radio').keypress((e) => {
    if (e.keyCode == 32) {
      $('.insurances-search__select-location').slideDown();
    }
  });
}(jQuery));
