/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  $('.carousel-multi-items').slick({
    centerMode: true,
    centerPadding: 0,
    slidesToShow: 1,
    infinite: true,
    variableWidth: true,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1330,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  });

  $(window).on('resize', () => {
    $('.carousel').slick('resize');
  });
}(jQuery));
