/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  // Bio accordion
  $('.bio-accordion .bio-accordion__button').click(function (e) {
    $('.bio-accordion .bio-accordion__button').removeClass('js-accordion-open').text('Read Bio');
    $('.bio-accordion .bio-accordion__bio-text').slideUp();

    if (
      $(this).parents('.bio-accordion__group').siblings('.bio-accordion__bio-text').is(':hidden') ==
      true
    ) {
      $(this).addClass('js-accordion-open').text('Hide Bio');
      $(this).parents('.bio-accordion__group').siblings('.bio-accordion__bio-text').slideDown();
    }
    e.preventDefault();
  });

  // Hide all bio text
  $('.bio-accordion .bio-accordion__bio-text').hide();
}(jQuery));
