/**
 * @file
 * A JavaScript file for the site.
 *
 * @copyright Copyright 2022 Palantir.net
 */

// JavaScript should be made compatible with libraries other than jQuery by
// wrapping it with an "anonymous closure". See:

// - http://www.adequatelygood.com/2010/3/JavaScript-Module-Pattern-In-Depth

(function ($) {
  $('.rail-filters--location__toggle a').click(function (e) {
    if ($(this).hasClass('location-toggle__list')) {
      $('.location-toggle__map').removeClass('is-active');
      $(this).addClass('is-active');

      $('.search-results__list').show();
      $('.search-results__map').hide();
    }

    if ($(this).hasClass('location-toggle__map')) {
      $('.location-toggle__list').removeClass('is-active');
      $(this).addClass('is-active');

      $('.search-results__list').hide();
      $('.search-results__map').show();
    }

    e.preventDefault();
  });
}(jQuery));
